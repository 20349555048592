<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Stock Adjustment</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Inventory / Stock Adjustment</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Stock Adjustment"
          class="mr-2"
          @click="$router.push({ name: 'StockAdjustmentAdd' })"
        />
        <Button
          type="button"
          icon="pi pi-refresh"
          label="Reset"
          class="p-button-outlined"
          @click="clearFilter()"
        />
      </div>
      <fx-table
        ref="table"
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :filters="filters"
        @request="onRequestData"
        @sort="onSort($event)"
      >
        <template #columns>
          <Column style="min-width: 3rem; flex: initial" header="" expander>
          </Column>
          <Column
            field="sop_no"
            header="Adjustment No."
            style="min-width: 12rem"
            :showFilterMenu="false"
            sortable
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="sop_at"
            header="Tgl Adjustment"
            style="min-width: 12rem"
            :showFilterMenu="false"
            sortable
          >
            <template #body="{ data }">
              {{ formatDate(data.sop_at) }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <CalendarFilter
                ref="calendarFilter"
                v-model="filterModel.value"
                showButtonBar
                numberOfMonths="2"
                @select="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="whouse"
            header="Gudang/Lokasi"
            style="min-width: 12rem"
            :showFilterMenu="false"
            sortable
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="assignee"
            header="Dihitung Oleh"
            style="min-width: 12rem"
            :showFilterMenu="false"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="status"
            header="status"
            style="min-width: 12rem"
            :showFilterMenu="false"
            sortable
          >
            <template #body="{ data }">
              <div :class="'status-badge status-' + data.status">
                {{ data.status }}
              </div>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <Dropdown
                v-model="filterModel.value"
                @change="filterCallback()"
                :options="list_status"
                optionValue="value"
                optionLabel="label"
                class="p-column-filter"
              >
              </Dropdown>
            </template>
          </Column>
          <Column style="flex-grow: 1; justify-content: flex-end">
            <template #body="{ data }">
              <!-- <Button
                v-if="data.status == 'done'"
                type="button"
                icon="pi pi-print"
                class="p-button-outlined p-button-secondary p-button-text mr-2"
                @click="onPrint(data)"
              /> -->
              <Button
                type="button"
                icon="pi pi-pencil"
                class="p-button-outlined p-button-secondary p-button-text mr-2"
                @click="onEditData(data)"
              />
              <Button
                type="button"
                icon="pi pi-trash"
                class="p-button-outlined p-button-danger p-button-text"
                @click="onConfirmDeletion(data)"
                :disabled="data.status == 'done'"
              />
            </template>
          </Column>
        </template>
      </fx-table>
    </div>
    <Dialog
      header="Hapus Stock Adjustment"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Stock Adjustment <strong>{{ form.sop_no }}</strong> akan dihapus.
          Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteStockAdjustment"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <Dialog
      :header="pdfTitle"
      v-model:visible="dialogViewer"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '90vw' }"
      :modal="true"
    >
      <div>
        <embed
          :src="pdfSource"
          type="application/pdf"
          width="100%"
          height="600px"
        />
      </div>
      <template #footer>
        <Button
          type="button"
          label="Download"
          icon="pi pi-download"
          class="p-button-text mr-2"
          @click="onDownloadPdf"
        />
        <Button
          type="button"
          label="Print"
          icon="pi pi-print"
          @click="onPrintPdf"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import PurchaseOrderService from '@/services/PurchaseOrderService'
import StockOpnameService from '@/services/StockOpnameService'
import FxTable from '@/components/inventory/GridSop'
import CalendarFilter from '@/components/CalendarFilter.vue'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'
import { formatDate } from '@/helpers/index'
import { saveAs } from 'file-saver'

export default {
  name: 'StockAdjustment',
  components: {
    CalendarFilter,
    FxTable,
    Hotkey,
  },
  data() {
    return {
      purchaseOrderService: null,
      stockOpnameService: null,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      items: [],
      form: {},
      PurchaseOrder: [],
      StockAdjustment: [],
      list_status: [
        { value: 'draft', label: 'Draft', level: 0 },
        { value: 'done', label: 'Done', level: 1 },
      ],
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'id',
        sortOrder: -1,
        filters: null,
      },
      filters: {
        sop_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
        sop_at: { value: null, matchMode: FilterMatchMode.CONTAINS },
        whouse: { value: null, matchMode: FilterMatchMode.CONTAINS },
        assignee: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      pdfSource: '',
      pdfFilename: '',
      pdfTitle: 'PDF Viewer ',
      dialogViewer: false,
    }
  },
  watch: {
    'options.filters'(value) {
      // custom Forcibly delete calendar data because it can't be deleted by default
      if (!value.sop_at.value) {
        this.$refs.calendarFilter.clearValueOnly()
      }
    },
  },
  created() {
    this.purchaseOrderService = new PurchaseOrderService()
    this.stockOpnameService = new StockOpnameService()
  },
  async mounted() {
    this.loadData()
  },
  methods: {
    clearFilter() {
      if (this.$refs.calendarFilter != undefined) {
        this.$refs.calendarFilter.clearValueOnly()
      }
      if (this.$refs.table != undefined) {
        this.$refs.table.initFilters()
      }
    },
    formatDate(value) {
      return formatDate(value)
    },
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.stockOpnameService
        .paginate(page, perPage, sortField, sortOrder, filters, 'adjustment')
        .then((res) => {
          this.items = res.data
          this.totalRecords = res.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Stock Adjustment', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onSort(e) {
      this.options.value = e
      this.loadData()
    },
    onPrintData(data) {
      this.stockOpnameService
        .print(data.id)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'PurchaseOrder.pdf')
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          errorHandler(err, 'Print Stock Adjustment', this)
        })
    },
    onDownloadPdf() {
      saveAs(this.pdfSource, this.pdfFilename)
    },
    onPrintPdf() {
      const iframe = document.createElement('iframe')
      iframe.src = this.pdfSource
      iframe.style.display = 'none'
      document.body.appendChild(iframe)

      // Use onload to make pdf preview work on firefox
      iframe.onload = () => {
        iframe.contentWindow.focus()
        iframe.contentWindow.print()
      }
    },
    onPrint(data) {
      this.stockOpnameService
        .print(data.id)
        .then((res) => {
          const blob = new Blob([res.data], { type: 'application/pdf' })
          const pdfUrl = URL.createObjectURL(blob)
          this.pdfSource = pdfUrl + '#toolbar=0'
          this.pdfFilename = 'purchase-order.pdf'
          this.pdfTitle = 'PDF Viewer (' + this.pdfFilename + ')'
          this.dialogViewer = true
        })
        .catch((err) => {
          errorHandler(err, 'Print Stock Adjustment', this)
        })
    },
    onEditData(data) {
      this.$router.push({
        name: 'StockAdjustmentEdit',
        params: { id: data.id },
      })
    },

    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeleteStockAdjustment() {
      this.stockOpnameService
        .delete(this.form.id)
        .then((res) => {
          if (res.data.status === 1) {
            this.$toast.add({
              severity: 'success',
              summary: 'Data Stock Adjustment',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data Stock Adjustment', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.$router.push({ name: 'PurchaseOrderAdd' })
      }
    },
    /* onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col))
    }, */
  },
}
</script>
